/* Center the modal overlay */
.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);  /* Adds a dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal content styling */
  .modal-content {
    padding: 30px;
    width: 500px;
    border-radius: 15px;
    background-color: #f4f7fc; /* Light background for a cleaner look */
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
  }
  
  .modal-title {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .modal-description {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .feedback-btn {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  
  .feedback-btn.thumbs-up {
    background-color: #4CAF50;
    color: #fff;
  }
  
  .feedback-btn.thumbs-down {
    background-color: #f44336;
    color: #fff;
  }
  
  .textarea-comment {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    font-size: 14px;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .confirm-btn {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .skip-btn {
    padding: 10px 20px;
    background-color: #f5b642;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .cancel-btn {
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
  }
  