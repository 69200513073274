/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f7f9;
  color: #333;
}

button {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  transition: all 0.3s ease;
}

button:hover {
  opacity: 0.9;
}

button:focus {
  outline: none;
}

/* Chat Screen Styling */
.chat-screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #333;
  color: white;
}

#chat-body {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #f4f7f9;
  display: flex;
  flex-direction: column;
}

#chat-input {
  display: flex;
  padding: 10px;
  background-color: #f0f0f0;
}

#chat-input input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

#chat-input button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
}

/* Message Bubbles */
.chat-message {
  max-width: 70%;
  margin-bottom: 15px;
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 14px;
  line-height: 1.4;
  position: relative;
}

/* User Messages (right-aligned) */
.chat-message.user {
  background-color: #dcf8c6;
  align-self: flex-end;
  border: 1px solid #a3cfa6;
}

/* Assistant Messages (left-aligned) */
.chat-message.assistant {
  background-color: #ffffff;
  align-self: flex-start;
  border: 1px solid #ddd;
}

/* Chat bubble triangles */
.chat-message.user::after {
  content: '';
  position: absolute;
  top: 0;
  right: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid #a3cfa6;
  border-top: 10px solid transparent;
}

.chat-message.assistant::after {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  width: 0;
  height: 0;
  border-right: 10px solid #ddd;
  border-top: 10px solid transparent;
}

/* Modal overlay */
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

/* Modal content */
.ReactModal__Content {
  border: none;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
}

.ReactModal__Content h2 {
  margin-bottom: 10px;
  font-size: 22px;
}

.ReactModal__Content p {
  margin-bottom: 20px;
}

/* Responsive behavior */
@media (max-width: 768px) {
  .chat-message {
    max-width: 90%;
  }
}
