/* Full page container to center content vertically and horizontally */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background-color: #f0f0f0;
  }
  
  /* The actual login box */
  .login-box {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 300px;
  }
  
  /* Input fields */
  .login-box input {
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
  }
  
  /* Submit button */
  .login-box button {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  /* Button hover effect */
  .login-box button:hover {
    background-color: #0056b3;
  }
  