.start-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
  background-color: #f8f9fa; /* Light background color */
}

.start-screen h1 {
  font-size: 3rem;
  color: #343a40;
  margin-bottom: 2rem;
}

.start-screen button {
  display: inline-block;
  margin: 0.5rem;
  padding: 1rem 2rem;
  font-size: 1.25rem;
  border: none;
  border-radius: 0.5rem;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.start-screen button:hover {
  background-color: #0056b3;
}

.start-screen button:focus {
  outline: none;
}
