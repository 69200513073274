/* Chat container style */
.chat-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background-color: #f0f4f8;
  font-family: 'Arial', sans-serif;
}

/* Chat header style */
#chat-header {
  background-color: #1f2937;
  color: white;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #ddd;
}

/* Style for the "Finish Session" button */
#chat-header button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #f5f5f5;
  color: #333;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#chat-header button:hover {
  background-color: #e0e0e0;
}

/* Chat messages container */
#chat-body {
  flex: 1;
  padding: 1.5rem;
  overflow-y: auto;
  background-color: #f8fafc;
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Style for individual messages */
.chat-message {
  padding: 0.9rem 1.2rem;
  margin: 0.5rem 0;
  border-radius: 12px;
  max-width: 75%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  line-height: 1.5;
}

/* User message (aligned right) */
.chat-message.user {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
  text-align: right;
  border-radius: 16px 16px 0 16px;
}

/* Assistant message (aligned left) */
.chat-message.assistant {
  background-color: #f1f1f1;
  color: #333;
  align-self: flex-start;
  text-align: left;
  border-radius: 16px 16px 16px 0;
  position: relative;
}

/* 'Thinking' message styling */
.chat-message.thinking {
  background-color: #e2e8f0;
  font-style: italic;
  color: #555;
}

/* Chat input container */
#chat-input {
  display: flex;
  padding: 1rem;
  background-color: white;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.05);
  border-top: 1px solid #ddd;
}

/* Chat input field */
#chat-input input {
  flex: 1;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-right: 1rem;
  background-color: #fff;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s ease;
}

#chat-input input:focus {
  border-color: #007bff;
  outline: none;
}

/* Send button style */
#chat-input button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

#chat-input button:hover {
  background-color: #218838;
}

/* Feedback section styling */
.feedback-section {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.feedback-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  border: none;
  padding: 10px 14px;
  border-radius: 50%;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.feedback-btn:hover {
  background-color: #e0e0e0;
}

.feedback-btn svg {
  font-size: 1.5rem;
}

.comment-section {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.comment-section textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: none;
  min-height: 80px;
  font-size: 1rem;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.05);
}

.send-feedback-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-feedback-btn:hover {
  background-color: #45a049;
}

/* Feedback confirmation message */
.feedback-thanks {
  margin-top: 10px;
  color: #4caf50;
  font-weight: bold;
  font-size: 1rem;
}

/* Scrollbar customization for chat body */
#chat-body::-webkit-scrollbar {
  width: 8px;
}

#chat-body::-webkit-scrollbar-thumb {
  background-color: #cbd5e0;
  border-radius: 4px;
}
